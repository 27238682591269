
@media screen and (max-width: 720px){
    .ant-table.ant-table-small .ant-table-title, .ant-table.ant-table-small .ant-table-footer, .ant-table.ant-table-small .ant-table-thead > tr > th, .ant-table.ant-table-small .ant-table-tbody > tr > td, .ant-table.ant-table-small tfoot > tr > th, .ant-table.ant-table-small tfoot > tr > td {
        padding: 20px 8px;
    }
    .ant-table tr > * {
        display: block;
    }
    .ant-table-thead{
        display: inline-block;
        position:absolute;
    }
    .ant-table tbody tr td{
        width: 108px !important;
        z-index: 0;
    }
    // .eventLog-table th:nth-of-type(1):before { height: 300px; }
    .ant-table thead tr th {
        // height: auto;
        background-color: rgb(247, 247, 247) !important;
        z-index: 2;
    }
    .ant-table tr {
        display: table-cell !important;
    }
    .ant-table thead tr{
        width: 86px !important;
    }
    
    .ant-table-tbody tr{
        width: 124px !important;
    }
}
/* Navbar */

.navbar {
  font-family: $type1;
  font-weight: 600;
  background: $navbar-bg;
  transition:background $action-transition-duration $action-transition-timing-function;
  -webkit-transition:background $action-transition-duration $action-transition-timing-function;
  -moz-transition:background $action-transition-duration $action-transition-timing-function;
  -ms-transition:background $action-transition-duration $action-transition-timing-function;
  --bs-navbar-toggler-focus-width: 0rem !important;
  .navbar-brand {
    width: 110px;
  }
  .navbar-brand-wrapper {
    transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    background: $nvabar-brand-bg;
    width: $sidebar-width-lg;
    height: $navbar-height;
    @media (max-width: 991px) {
      width: 55px;
    }
    .navbar-brand {
      color: color(gray-dark), 20%;
      font-size: 1.5rem;
      line-height: 48px;
      margin-right: 0;
      padding: .25rem 0;
      width: 100%;

      &:active,
      &:focus,
      &:hover {
        color: color(gray-dark), 10%;
      }

      img {
        width: calc(#{$sidebar-width-lg} - 120px );
        max-width: 100%;
        height: 28px;
        margin: auto;
        vertical-align: middle;
      }
      &.brand-logo-mini {
        display: none;
        img {
          width: calc(#{$sidebar-width-icon} - 50px );
          max-width: 100%;
          height: 28px;
          margin: auto;
        }
      }
    }
  }
  .navbar-menu-wrapper {
    transition: width $action-transition-duration $action-transition-timing-function;
    -webkit-transition: width $action-transition-duration $action-transition-timing-function;
    -moz-transition: width $action-transition-duration $action-transition-timing-function;
    -ms-transition: width $action-transition-duration $action-transition-timing-function;
    color: $navbar-menu-color;
    padding-left: 24px;
    padding-right: 24px;
    width: calc(100% - #{$sidebar-width-lg});
    height: $navbar-height;
    @media (max-width: 991px) {
      width: auto;
      padding-left: 15px;
      padding-right: 15px;
    }

    .navbar-toggler {
      border: 0;
      color: $nvabar-toggler-color;
      height: $navbar-height;
      @include border-radius(0px);
      padding-left: 0px;
      padding-right: 20px;
      &:not(.navbar-toggler-right) {
        font-size: .875rem;
        @media (max-width: 991px) {
          display: none;
        }
      }
      &.navbar-toggler-right{
        @media (max-width:991px){
          padding-left: 15px;
          padding-right: 11px;
          border-right: none;
        }
      }
    }

    .search-field {
      .input-group {
        background: $navbar-search-bg;
        width: 305px;
        input {
          font-size: $default-font-size;
          padding: .5rem;
         input-placeholder {
            font-size: $navbar-font-size;
            color: $navbar-menu-color;
            font-family: $type1;
            font-weight: 400;
          }
        }
        i {
          font-size: 14px;
          margin-right: 0;
          color: $nvabar-search-icon-color;
        }
        .input-group-text {
          background: transparent;
          .rtl & {
            padding : 0.94rem 1rem 0.94rem .5rem
          }
        }
      }
      .rtl & {
       // @extend .ml-0;
        //@extend .mr-4;
      }
    }

    .count-indicator {
      position: relative;

      .count-symbol,
      .count-number {
        position: absolute;
        border-radius: 100%;
        border: 2px solid $white;
      
        animation: pulse 3s ease-out;
        animation-iteration-count: infinite;
      }
      
      @keyframes pulse {
        40% {
          transform: scale3d(1, 1, 1);
        }
      
        50% {
          transform: scale3d(1.5, 1.5, 1.5);
        }
      
        55% {
          transform: scale3d(1, 1, 1);
        }
        
        60% {
          transform: scale3d(1.5, 1.5, 1.5);
        }
      
        65% {
          transform: scale3d(1, 1, 1);
        }
      }
      .count-symbol {
        top: 20px;
        right: 0px;
        width: 10px;
        height: 10px;
      }
      .count-number {
        min-width: 14px;
        height: 14px;
        font-size: .5rem;
        color: $white;
        bottom: 16px;
        right: -5px;
        line-height: 1;
        text-align: center;
      }
      &:after {
        display: none;
      }
    }
    .navbar-nav {
      flex-direction: row;
      align-items: center;
      .nav-item {
        .nav-link {
          color: inherit;
          font-size: $navbar-font-size;
          margin-left: .625rem;
          margin-right: .625rem;
          height: $navbar-height;
          display:flex;
           align-items:center;
          font-weight: 600;
          @media (max-width: 767px) {
            margin-left: .8rem;
            margin-right: .8rem;
          }
          i {
            font-size: $navbar-icon-font-size;
          }
        }
        .dropdown {
          .dropdown-toggle {
            border: 0;
            padding: 0;
            background: transparent;
            &:focus {
              box-shadow: none;
            }
            &:after {
              color: theme-color(light);
              font-size: 1.25rem;
              content: "\f35d";
              display: inline-block;
            }
            &.hide-carret {
              &:after {
                display: none;
              }
            }
          }
          .dropdown-menu { 
            @extend .dropdownAnimation;
            border: none;
            -webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
            min-width: 220px;;
            &.navbar-dropdown {
              position: absolute;            
              font-size: 0.9rem;
              margin-top: 0;
              .rtl & {
                right: auto;
                left: 0;
              }
              padding: 0;

              .dropdown-item {
                @extend .d-flex;
                @extend .align-items-center;
                margin-bottom: 0;
                padding: 11px 13px;
                cursor: pointer;
                color: $nav-dropdown-color;
                font-size: .875rem;
                font-style: normal;
                font-family: $type1;
                font-weight: 600;
                .badge {
                  padding: 2px 6px;
                  font-size: 10px;
                }

                i {
                  font-size: 17px;
                  .rtl & {
                   // @extend .mr-2;
                  }
                }

                .ellipsis {
                  max-width: 300px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                .rtl & {
                  i{
                   // @extend .mr-0;
                    margin-left: 10px;
                  }
                }
              }

              .dropdown-divider {
                margin: 0;
              }
            }
          }
          @media (max-width: 991px) {
            position: static;
            .navbar-dropdown {
              left: 20px !important;
              right: 20px;
              top: $navbar-height;
              width: calc(100% - 40px);
            }
          }
        }
        &.nav-settings {
          padding-left: 20px;
          padding-right: 5px;
          .nav-link {
            margin-right: 0;
            margin-left: 0;
          }
        }
        &.nav-profile {
          .nav-link {
            @extend .d-flex;
            .nav-profile-img {
              position: relative;
              width: 32px;
              height: 32px;
              img {
                width: 32px;
                height: 32px;
                border-radius: 100%;
              }
            }
            .nav-profile-text {
              margin-left: .875rem;
              .rtl & {
                margin-left: 0;
                margin-right: .875rem;
              }
              p {
                line-height: 1;
              }
              @media (max-width: 767px) {
                display: none;
              }
            }
            &.dropdown-toggle {
              &:after {
                line-height: 2;
              }
            }
          }
        }
        &.nav-language {
          .nav-link {
            @extend .d-flex;
            .nav-language-icon {
              position: relative;
              width: 22px;
              height: 20px;
              i {
                font-size: 1rem;
              }
            }
            .nav-language-text {
              margin-left: .875rem;
              .rtl & {
                margin-left: 0;
                margin-right: .875rem;
              }
              p {
                line-height: 1;
              }
              @media (max-width: 767px) {
                display: none;
              }
            }
            &.dropdown-toggle {
              &:after {
                line-height: 2;
              }
            }
          }
        }
      }
      &.navbar-nav-right {
        @media (min-width: 992px) {
          margin-left: auto;
          .rtl & {
            margin-left: 0;
            margin-right: auto;
          }
        }
      }
    }
  }
}
@media (max-width:991px) {
  .navbar {
    flex-direction: row;
    .navbar-brand-wrapper {
      width: 75px;
      .navbar-brand {
        &.brand-logo {
          display: none;
        }
        &.brand-logo-mini {
          display: inline-block;
        }
      }
    }
  }

  .navbar-collapse {
    display: flex;
    margin-top: 0.5rem;
  }
}

@media (max-width:480px) {
  .navbar {
    .navbar-brand-wrapper {
      width: 55px;
      .brand-logo-mini{
        padding-top: 0px;
      }
    }
  }
} 
/* Navbar */


.bg-danger, .settings-panel .color-tiles .tiles.danger {
  background: #fc5a5a;
}

//overriding css of amplify-react-ui for left margin
.align-header {
  place-self: auto;
  left: var(--amplify-components-authenticator-modal-left);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}
.AuthHeader-logo-align{
  background: #fff !important;
  height: 53px;
  width: 19rem;
  -webkit-transition: width .25s ease,background .25s ease;
}
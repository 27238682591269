/* Icons */

.icons-list {
  border-left: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  >div {
    background: $white;
    border-top: 1px solid $border-color;
    border-right: 1px solid $border-color;
    display:flex;
    align-items:center;
    padding:15px 15px;
    font-family: $type1;
    font-weight: 400;
    font-size: $default-font-size;

    i {
      display: inline-block;
      font-size: 20px;
      width: 40px;
      text-align: left;
      color: theme-color(primary);
    }
  }
}
